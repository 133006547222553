<template>
    <div class="file-input">
        <slot v-bind="{ files, state: readonly(state), open }">
            <div v-if="files && files.length > 0">
                <div v-for="file in files">
                    {{ file.name }}
                </div>
            </div>
            <div v-else>
                <div>选择文件</div>
            </div>
        </slot>
        <input ref="inputRef" type="file" :accept="accept" @change="onFileChange" :capture="capture" :multiple="max > 1" />
    </div>
</template>

<script setup lang="ts">
import { InputHTMLAttributes, reactive, readonly, ref } from 'vue';
export type Transform = (file: File, index: number, reportProgress: (progress: number) => void) => PromiseLike<File> | File
const props = withDefaults(defineProps<{
    files?: File[],
    accept?: string,
    capture?: InputHTMLAttributes['capture']
    max?: number,
    transform?: Transform
}>(), {
    max: 1,
});

const emit = defineEmits<{
    (e: 'update:files', files?: File[]): void
}>()

const state = reactive({
    isTransforming: false,
    transformingProgress: 0
})

const inputRef = ref<HTMLInputElement>()

const onFileChange = async (e: Event) => {
    const filelist = (e.target as HTMLInputElement).files
    if (filelist) {
        const files = Array.from(filelist).slice(0, props.max)
        if (props.transform) {
            const transform = props.transform
            state.isTransforming = true
            state.transformingProgress = 0
            const promises = files.map((file,index) => {
                state.isTransforming = true
                state.transformingProgress = 0
                const filesOrPromise = transform(file,index, progress => { })
                if ('then' in filesOrPromise) {
                    return filesOrPromise
                } else {
                    return Promise.resolve(filesOrPromise)
                }
            })
            try {
                const transformedFiles = await Promise.all(promises)
                state.isTransforming = false
                state.transformingProgress = 1
                emit('update:files', transformedFiles)
            }catch (e) {
                state.isTransforming = false
                state.transformingProgress = 0
            }
        } else {
            emit('update:files', files)
        }
    } else {
        emit('update:files', undefined);
    }
}

function open() {
    inputRef.value?.click()
}

</script>

<style scoped>
.file-input input {
    display: flex;
    opacity: 0;
    height: 0;
    width: 0;
}
</style>