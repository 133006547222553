import { resultData , http } from "src/http";

// export function checkOpenChannel(params) {
//     return resultData.post<AppIndexBox>('/ka/app/v1/checkOpenChannel',params);
// }

export function checkOpenChannel(param:any) {
    return http.resultData.post('/jhserver/ka/app/v1/checkOpenChannel',param);
}

export type AppIndexBox = {
    "title": string,
    "content": string,
    "leftBtn": string,
    "rightBtn": string,
    "btnTop": string,
    "btnDown": string,
    "topType": number,
    "downType": number,
    "url": string,
    "iosUrl": string,
    "bottomStr": string,
    "show": Boolean,
    "btnStr": string,
    "type": number
}
