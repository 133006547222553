<template>
    <div class="order-list" v-lazy-root>
        <div class="ml-4 mb-4">
            <DateInput v-model:value="date" type="date" format="YYYY-MM-DD" @update:value="reloadOrderList"></DateInput>
        </div>
        <div class="empty" v-if="orderList.value === undefined || orderList.value.length <= 0">
            <div>
                <img src="./assets/empty.svg" alt="">
                <p>暂无订单</p>
            </div>
        </div>
        <f7Link class="cell" v-for="item in orderList.value" :key="item.id" :href="`/order/${item.tradeNo}`">
            <RoundCard class="p-4">
                <DynamicFields :fields="item.orderInfo"></DynamicFields>
                <div class="flex justify-end mt-3" v-if="item.showCloseBtn || item.showPayBtn">
                    <f7Button class="w-[98px]" round outline @click="askCloseOrder(item.tradeNo)">关闭订单</f7Button>
                    <f7Button class="w-[98px] ml-3" round fill :href="`/collection/${item.tradeNo}`">去收款</f7Button>
                </div>
            </RoundCard>
        </f7Link>
        <!-- 当这玩意儿出现在屏幕中，传给v-appear的函数会被调用。key是必须的 -->
        <div class="flex justify-center py-4" v-if="orderList.hasMore" key="bottom-loader"
             v-appear="() => orderList.loadMore()">
            <f7Preloader size="20"></f7Preloader>
        </div>
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { f7Button, f7Preloader, f7Link } from 'framework7-vue';
import DynamicFields from 'src/components/DynamicFields/DynamicFields.vue';
import DateInput from 'src/components/Inputs/DateInput.vue';
import RoundCard from 'src/components/RoundControls/RoundCard.vue';
import { makeLoader, makePagedLoader } from 'src/Loader';
import { computed, reactive, ref } from 'vue';
import * as api from '../api';

const date = ref(new Date())
const orderList = reactive(makePagedLoader(api.getOrderList))

const closeOrder = makeLoader(api.closeOrder).withIndicator()
    .showError()
    .showSuccess('订单已关闭')

function reloadOrderList() {
    return orderList.withIndicator().load({ state: api.OrderState.待支付, time: dayjs(date.value).format('YYYY-MM-DD') })
}

function askCloseOrder(tradeNo: string) {
    window.f7.dialog.confirm('', '确定关闭此订单？', () => {
        closeOrder.load(tradeNo).then(reloadOrderList)
    }).open()
}

defineExpose({
    load: reloadOrderList,
    total: computed(() => orderList.total)
})
</script>

<style scoped>
.order-list {
    overflow-y: auto;
    padding-top: 16px;
    padding-bottom: 16px;
}

.cell {
    display: block;
    margin-left: 16px;
    margin-right: 16px;
}

.cell+.cell {
    margin-top: 16px;
}

.empty {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}
.empty p {
    text-align: center;
    color: #989898;
    font-size: 16px;
    margin: 0;
}
</style>
