const originCheckValidity = HTMLFormElement.prototype.checkValidity

HTMLFormElement.prototype.checkValidity = function () {
    for (let i = 0; i < this.elements.length; i++) {
        const el = this.elements[i]
        if (el.tagName == 'INPUT' || el.tagName == 'TEXTAREA') {
            checkValidityFor(el as HTMLInputElement | HTMLTextAreaElement)
        }
    }
    return originCheckValidity.apply(this)
}

const originReportValidity = HTMLFormElement.prototype.reportValidity
HTMLFormElement.prototype.reportValidity = function () {
    for (let i = 0; i < this.elements.length; i++) {
        const el = this.elements[i]
        if (el.tagName == 'INPUT' || el.tagName == 'TEXTAREA') {
            checkValidityFor(el as HTMLInputElement | HTMLTextAreaElement)
        }
    }
    return originReportValidity.apply(this)
}

const originInputCheckValidity = HTMLInputElement.prototype.checkValidity

HTMLInputElement.prototype.checkValidity = function () {
    checkValidityFor(this)
    return originInputCheckValidity.apply(this)
}

const originInputReportValidity = HTMLInputElement.prototype.reportValidity

HTMLInputElement.prototype.reportValidity = function () {
    checkValidityFor(this)
    return originInputReportValidity.apply(this)
}

function checkValidityFor(input: HTMLInputElement | HTMLTextAreaElement) {
    const value = input.value
    if (input.maxLength > -1 && value.length && value.length > input.maxLength && !input.validity.tooLong) {
        input.setCustomValidity(`使用最多${input.maxLength}个字符，当前${value.length}个`)
    } else {
        input.setCustomValidity('')
    }
    if (input.minLength > -1 && value.length && value.length < input.minLength && !input.validity.tooShort) {
        input.setCustomValidity(`使用至少${input.minLength}个字符，当前${value.length}个`)
    } else {
        input.setCustomValidity('')
    }
}