import 'tailwindcss/base.css'
import 'src/extensions'

import { createApp } from 'vue'

import App from './App.vue'
const app = createApp(App)

import VConsole from 'vconsole';

// 判断是否为开发环境，仅在开发环境下使用vConsole
if (process.env.NODE_ENV === 'development') {
    const vConsole = new VConsole();
    // 或者使用配置参数来初始化
    // const vConsole = new VConsole({ theme: 'dark' });
}

import { createPinia } from 'pinia'
const pinia = createPinia()
app.use(pinia)

import InitF7 from './Plugins/InitFramework7'
app.use(InitF7)

// import Injections from './Plugins/Injections'
// app.use(Injections)

import vLazy from './Plugins/vLazy'
app.use(vLazy)

import mitt from 'mitt'
const mit = mitt()
app.config.globalProperties.$bus = mit

import './themes/style.css'
import 'tailwindcss/components.css'
import 'tailwindcss/utilities.css'
import './Plugins/InterceptBack'

app.mount('#app')




