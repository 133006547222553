<template>
    <Page v-bind="$props" title="testA" navbar>
        <f7Link href="/testc">testc</f7Link>
    </Page>
</template>

<script setup lang="ts">
import { f7Link } from 'framework7-vue';
import { Router } from 'framework7/types';
import Page from 'src/components/Pages/Page.vue';
const props = defineProps<{
    f7router: Router.Router
    f7route: Router.Route
}>();

console.log(props)

</script>

<style scoped>

</style>