import type { Plugin } from "vue";

const globalObserver = new IntersectionObserver((entries) => {
    for (const entry of entries) {
        if (entry.isIntersecting) {
            entry.target.appearAction && entry.target.appearAction()
        } else {
            entry.target.disappearAction && entry.target.disappearAction()
        }
    }
})

const install: Plugin = (app) => {

    app.directive('lazy-root', {
        beforeMount: (el, binding) => {
            el.lazyRootObserver = new IntersectionObserver((entries) => {
                for (const entry of entries) {
                    if (entry.isIntersecting) {
                        entry.target.appearAction && entry.target.appearAction()
                    } else {
                        entry.target.disappearAction && entry.target.disappearAction()   
                    }
                }
            }, {
                root: el,
                threshold: 0.5
            })
            el.lazyRootObserver.intersectings = new Set()
        },
        unmounted: (el, binding) => {
            el.lazyRootObserver && el.lazyRootObserver.disconnect()
            delete el.lazyRootObserver
        }
    })

    function getLazyRootObserver(el) {
        let cur = el.parentElement
        while (cur) {
            if (cur.lazyRootObserver) {
                return cur.lazyRootObserver
            } else {
                cur = cur.parentElement
            }
        }
    }

    function updateObserver(el) {
        const ob = getLazyRootObserver(el)
        if (el.bindingObserver && el.bindingObserver === ob) {
            return
        }
        el.bindingObserver && el.bindingObserver.unobserve(el)
        el.bindingObserver = ob
        if (ob) {
            ob.observe(el)
        }
    }

    app.directive('appear', {
        mounted: (el, binding) => {
            console.log('inserted')
            el.appearAction = binding.value
            updateObserver(el)
        },
        updated: (el, binding) => {
            console.log('update')
            el.appearAction = binding.value
            updateObserver(el)
        },
        unmounted: (el, binding) => {
            console.log('unbind')
            el.appearAction = undefined
            el.bindingObserver && el.bindingObserver.unobserve(el)
        }
    })

    app.directive('disappear', {
        mounted: (el, binding) => {
            el.disappearAction = binding.value
            updateObserver(el)
        },
        updated: (el, binding) => {
            el.disappearAction = binding.value
            updateObserver(el)
        },
        unmounted: (el, binding) => {
            el.disappearAction = undefined
            el.bindingObserver && el.bindingObserver.unobserve(el)
        }
    })

}


export default install