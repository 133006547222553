export function externalImport(src: string) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = src;
        script.onload = () => {
            script.remove()
            resolve(void 0)
        }
        script.onerror = reject
        document.head.appendChild(script)
    })
}