<template>
    <Transition :duration="1000" enter-active-class="animate__bounceIn" leave-active-class="animate__bounceOut">
        <div v-if="!isShown.error && isShown.value === true" class="red-env relative animate__animated z-10" v-bind="$attrs"
             :style="{ 'pointer-events': isShown.value && isShown.isLoading ? 'none' : 'auto' }">
            <slot v-bind="{ open }"></slot>
            <Transition name="slide-t" :duration="150">
                <div v-if="data.isTooltipVisible" class="red-env-tooltip" @click="data.isTooltipVisible = false">
                    您有白条福利活动(提额/免息)可供用户参加，点击此处参加活动！
                </div>
            </Transition>
        </div>
    </Transition>
    <f7Sheet class="h-auto rounded-t-lg" swipe-to-close backdrop :opened="data.isOpened"
               @sheet:open="data.showQrCode = false" @sheet:opened="data.showQrCode = true"  @sheet:closed="data.onClose" style="--f7-sheet-bg-color: #fff">
        <RoundPopup :readonly="readonly" :show-q-r-code="data.showQrCode" style="padding-bottom: var(--f7-safe-area-bottom,0px)" title="白条福利活动码" :params="data.isOpened ? params : undefined"
                    @update:amount="emit('update:amount', $event)" @continue="data.onContinue"></RoundPopup>
    </f7Sheet>
</template>
<script lang="ts">
let isTipShown = false
</script >

<script setup lang="ts">
import { f7Sheet } from 'framework7-vue';
import { makeLoader } from 'src/Loader';
import { nextTick, reactive, watch } from 'vue';
import RoundPopup from './RoundPopup/RoundPopup.vue';
import * as api from '../api';
const props = defineProps<{
    params?: api.RedPacketCodeParams,
    readonly?: boolean,
}>()

const data = reactive({
    isOpened: false,
    onClose: () => { },
    onContinue: () => { },
    isTooltipVisible: false,
    isAlreadyShown: false,
    openTime: 0,
    showQrCode: false,
})

const emit = defineEmits<{
    (event: 'update:amount', amount: string): void
}>()

const isShown = makeLoader(api.queryRedPacketCodeState).onLoad((value) => {
    value && nextTick(showTooltipIfNeeds)
}).reactive()

if (props.params) {
    isShown.load(props.params)
}

watch(() => props.params, (params) => {
    if (params) {
        isShown.load(params)
    } else {
        isShown.reset()
    }
})

function open(): Promise<void> {
    data.openTime = new Date().getTime()
    data.isTooltipVisible = false
    data.isOpened = true
    return new Promise<void>((resolve, reject) => {
        data.onClose = () => reject(new Error('用户不继续收款'))
        data.onContinue = resolve
    }).finally(() => {
        data.isOpened = false
        //打开时长超过10秒钟，这次使用不再提示
        if (new Date().getTime() - data.openTime > 10000) {
            data.isAlreadyShown = true
        }
    })
}

async function askUseIfNeeds(): Promise<void> {
    if (isShown.isLoading || isShown.error || !isShown.value) {
        return Promise.resolve()
    }
    let dontShowAgain = localStorage.getItem('dontShowAgain:RedPacketCode') === 'true'
    if (data.isAlreadyShown || dontShowAgain) {
        return Promise.resolve()
    }
    try {
        await new Promise<void>((resolve, reject) => {
            const dialog = window.f7.dialog.create({
                'text': `您有白条福利活动(提额/免息)可供用户参加，是否参加？`,
                'buttons': [
                    {
                        'text': '不参加', cssClass: 'no-join-btn', 'onClick': () => {
                            //可以看情况打开
                            // data.isAlreadyShown = true 
                            reject(new Error('用户选择不参加'))
                        }
                    },
                    { 'text': '参加', cssClass: 'join-btn', 'onClick': () => resolve(undefined) }
                ],
            })
            dialog.open()
            dialog.el.style.overflow = 'visible'
            const div = document.createElement('div')
            div.className = "dont-show-again"
            const label = document.createElement('label')
            label.className = "checkbox"
            div.appendChild(label)
            const checkbox = document.createElement('input')
            checkbox.type = 'checkbox'
            checkbox.checked = dontShowAgain
            checkbox.addEventListener('change', () => dontShowAgain = checkbox.checked)
            label.appendChild(checkbox)
            const i = document.createElement('i')
            i.className = "icon-checkbox"
            label.appendChild(i)
            const text = document.createTextNode('不再提示')
            div.appendChild(text)
            dialog.el.appendChild(div)
        }).finally(() => {
            localStorage.setItem('dontShowAgain:RedPacketCode', dontShowAgain.toString())
        })
        // 用户选择参加
        return open()
    } catch (e) {
        // 用户选择不参加
        return undefined
    }
}

function showTooltipIfNeeds() {
    if (isTipShown) return
    // if (localStorage.getItem('dontShowAgain:Tooltip') === 'true') return
    data.isTooltipVisible = true
    // localStorage.setItem('dontShowAgain:Tooltip', 'true')
    isTipShown = true
}

defineExpose({
    open,
    askUseIfNeeds
})

</script>

<style scoped>
.red-env-tooltip {
    background: #303133;
    border-radius: 4px;
    padding: 7px 10px 7px 10px;
    color: white;
    font-size: 11px;
    line-height: 18px;
    display: flex;
    align-items: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    position: absolute;
    width: 141px;
    top: calc(100% + 8px);
    left: calc(50% - 20px);
    z-index: 10;
}

.red-env-tooltip::after {
    content: '';
    position: absolute;
    left: 12px;
    top: -6px;
    border-width: 0px 8px 6px 8px;
    border-style: solid;
    border-color: transparent transparent var(--f7-tooltip-bg-color) transparent;
}
</style>

<style>
.dialog-button.join-btn {
    font-size: 16px;
}

.dialog-button.no-join-btn {
    font-size: 16px;
    color: #353535;
}
</style>