import { Loader } from "../Loader";


export class EventHander<Value, Params extends any[]> extends Loader<Value, Params> {
    constructor(
        public readonly origin: Loader<Value, Params>,
        private onloadstarts: ((...args: Params) => void)[],
        private onloads: ((value: Value) => void)[],
        private onerrors: ((error: Error) => void)[]) {
        super();
    }
    async load(...args: Params) {
        try {
            this.onloadstarts.forEach(fn => fn(...args));
            const value = await this.origin.load(...args);
            this.onloads.forEach(fn => fn(value));
            return value;
        } catch (error: any) {
            this.onerrors.forEach(fn => fn(error));
            throw error;
        }
    }

    onLoadstart(listener: (...args: Params) => void): Loader<Value, Params> {
        this.onloadstarts.push(listener);
        return this;
    }
    onLoad(listener: (value: Value) => void): Loader<Value, Params> {
        this.onloads.push(listener);
        return this;
    }
    onError(listener: (error: Error) => void): Loader<Value, Params> {
        this.onerrors.push(listener);
        return this;
    }
    onFinal(listener: () => void): Loader<Value, Params> {
        this.onloads.push(listener);
        this.onerrors.push(listener);
        return this
    }
}

declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        onLoadstart(listener: (...args: Params) => void): Loader<Value, Params>;
        onLoad(listener: (value: Value) => void): Loader<Value, Params>;
        onError(listener: (error: Error) => void): Loader<Value, Params>;
        onFinal(listener: () => void): Loader<Value, Params>;
    }
}

Loader.prototype.onLoadstart = function (listener: any) {
    return new EventHander(this,[listener], [], []);
}

Loader.prototype.onLoad = function (listener: any) {
    return new EventHander(this,[], [listener], []);
}

Loader.prototype.onError = function (listener: any) {
    return new EventHander(this,[], [], [listener]);
}

Loader.prototype.onFinal = function (listener: any) {
    return new EventHander(this,[], [listener], [listener])
}
