import { Cancelled } from "./Errors";

declare global {
    interface Promise<T> {
        cancel(reason?: any): void
        tee(): Promise<T>
    }
}

const originThen = Promise.prototype.then;

Promise.prototype.tee = function () {
    return new Promise((resolve, reject) => {
        this.then(resolve, reject)
    })
}

Promise.prototype.then = function (onFulfilled, onRejected) {
    const p = originThen.bind(this)(onFulfilled, onRejected)
    p.cancel = this.cancel;
    return p;
}

window.Promise = new Proxy(Promise, {
    construct(target, argArray, _) {
        const controller = new AbortController();
        const signal = controller.signal;
        const p = new target((resolve, reject) => {
            signal.onabort = function () {
                reject(new Cancelled(this.reason))
            }
            argArray[0](resolve, reject)
        })
        p.cancel = controller.abort.bind(controller)
        return p
    }
})


window.fetch = new Proxy(window.fetch, {
    apply(target, thisArg, argArray) {
        if (argArray[1]?.signal) {
            const p = target.bind(thisArg)(argArray[0], argArray[1])
            p.cancel = () => console.warn('you have provided a signal, use its controller to cancel')
            return p
        } else {
            const controller = new AbortController();
            const signal = controller.signal;
            const p = target(argArray[0], {
                signal,
                ...argArray[1]
            })
            p.cancel = controller.abort.bind(controller)
            return p
        }
    }
})

export { }