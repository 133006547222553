<template>
    <FilesInput v-model:files="data.files" v-slot="{ state, open }" :max="1" :transform="upload" accept="image/*" :capture="capture">
        <div class="h-full w-full relative rounded overflow-hidden" @click="open">
            <slot v-if="value" name="preview">
                <Image class="h-full w-full object-cover" v-if="value" :src="value"></Image>
            </slot>
            <slot v-else>
                <div>请上传图片</div>
            </slot>
            <div v-if="state.isTransforming"
                 class="absolute h-full w-full top-0 left-0 flex items-center justify-center bg-black bg-opacity-30">
                <f7Preloader color="white" size="24" />
            </div>
        </div>
    </FilesInput>
</template>

<script setup lang="ts">
import { f7Preloader } from 'framework7-vue';
import FilesInput, { Transform } from 'src/components/Inputs/FilesInput.vue';
import { InputHTMLAttributes, reactive } from 'vue';
import { uploadFile } from 'src/http';
import Image from '../Image/Image.vue';

const props = defineProps<{
    value?: string,
    capture?: InputHTMLAttributes['capture']
    transform?: Transform
}>()

const emit = defineEmits<{
    (e: 'update:value', value?: string): void
}>();

const data = reactive({
    files: [] as File[]
})

async function upload(file: File, index: number) {
    if (file.size / 1024 > 10240) {    //  不大于10M   10M=10240
        window.f7.toast.show({
            text: "图片大小不能大于10M",
            position: 'center',
            destroyOnClose: true,
            closeTimeout: 3000
        })
        return;
    }else{
        const url = await uploadFile.showError().load(await props.transform?.(file, index, () => { }) || file);
        if (index === 0) {
            emit('update:value', url);
        }
        return file
    }
}

</script>

<style scoped>

</style>