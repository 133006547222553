import { Router } from "framework7/types"
import List from './订单列表/index.vue'
import shopList from './商户订单列表/index.vue'
const Detail = () => import('./订单详情/index.vue')
const orderConfirmation = () => import('./订单确认/index.vue')

const routes: Router.RouteParameters[] = [
    {
        path: '/order/list',
        component: List
    },
    {
        path: '/order/shopList',
        component: shopList
    },
    {
        path: '/order/:tradeNo',
        asyncComponent: Detail
    },
    {
        path: '/orderConfirmation/:tradeNo',
        asyncComponent: orderConfirmation
    }
]

export default routes
