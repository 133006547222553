<template>
    <div class="box" v-if="indexData.value">
        <div class="box-1">
            <div class="content">
                <div class="logo">
                    <img src="./assets/logo1@3x.png" style="width: 56px"/>
                </div>
                <div class="text-center" style="padding-top: 12px;">
                    <span style="color: #FFFFFF;font-size: 14px!important" >欢迎您使用京聚汇</span>
                </div>


                <div class="content-list">
                    <div class="tab1"></div>
                    <div class="tab1-top" style="border-radius: 10px;overflow: hidden;padding-bottom: 12px;border-bottom: #E6E6E6 1px dashed;">
                        <p style="font-weight: bolder;padding-top: 10px;font-size: 18px">{{indexData.value?.title}}</p>
                        <p style="color: #979797;padding: 0 30px 10px 30px">{{indexData.value?.content}}</p>
                    </div>
                    <div style="border-radius: 10px;overflow: hidden;background: white;padding-bottom: 12px;"> 
                        <div class="content-info" style="margin-top: 25px;">
                            <img src="./assets/baitiao.svg" class="content-img" />

                            <div class="content-middle">
                                <span style="font-weight: bold;font-size: 16px;margin-left: 15px">京东白条</span>
                                <div style="color: #989898; align-items: left; margin-left: 15px;width:100%;text-align:left">
                                    <div class="content-span" style="display:inline-block" v-if="indexData.value && indexData.value.leftBtn"><span >{{indexData.value?.leftBtn}}</span></div>
                                    <div class="content-span" style="display:inline-block" v-if="indexData.value && indexData.value.rightBtn"><span >{{indexData.value?.rightBtn}}</span></div>
                                </div>
                            </div>
                        </div>
                        <ActionButton fill type="info" class="mt-4 mx-4" style="margin-top: 24px;" v-if="indexData.value?.btnTop" @click="navigate">{{indexData.value?.btnTop}}</ActionButton>
                        <ActionButton fill type="info" class="mt-4 mx-4" style="margin-top: 24px;" v-if="indexData.value?.btnDown" @click="navigate">{{indexData.value?.btnDown}}</ActionButton>
                        <p style="font-size: 16px">{{indexData.value?.bottomStr}}</p>
                        <div v-if="!indexData.value?.bottomStr" style="height: 16px;width: 100%;"></div>
                    </div>
                    
                    <p style="text-decoration-line: underline;color: #FFFFFF;font-size: 14px;line-height: 26px;" @click="toLogin">返回登录页面</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup lang="ts">
import { Router } from 'framework7/types';
import { defineProps, reactive ,defineEmits,ref, onMounted,nextTick} from 'vue';
import ActionButton from 'src/components/Inputs/ActionButton.vue';
import {h5IndexPageApi, AppIndexBox} from './api'
import { makeLoader } from 'src/Loader';
import {queryRegisterState} from "../api";
const props = defineProps<{
    inviteCode:string | null,
    storeNo:string | null | undefined,
}>();

const data = reactive({
    indexData:{
        title:'京东白条',
        content:'您还未进行实名认证\n请提交实名认证资料',
        leftBtn:'未实名',
        rightBtn:'产品未开通',
        // btnTop:'免费申请',
        // btnDown:'实名认证',
        topType:0,
        downType:0,
        bottomStr:'申请审核中，请稍后查看',
    },
    registerState:{
        content:'您还未注册，请注册成功后再进行操作',
        url:'',
        state:0,
        btn:'去注册'
    }
});

const emit = defineEmits(['navigate','logout']);

//跳转
const navigate = () => {
    let data = indexData.value
    let type = data?.topType||data?.downType
    switch (type) {
        case 1:
            makeLoader(queryRegisterState)
                .showError()
                .load()
                .then(res => {
                    if(res.state == 1){
                        emit('navigate',{
                            path:'/manualEntry/step2',
                            query:{}
                        });
                    }else{
                        emit('navigate',{
                            path:'/manualEntry/step1',
                            query:res
                        });
                    }
                })

            break;
        case 2:
            emit('navigate',{
                path:'/manualEntry/step2',
                query:{}
            });
            break;
        case 3:
            emit('navigate',{
                path:'/manualEntry/step1',
                query:{
                    btn: data.btnDown,
                    content: data.content,
                    url: data.url,
                    step: 2
                }
            });
            break;
        default:
            break;
    }

}
const indexData = makeLoader(h5IndexPageApi)
                .withIndicator()
                .mapParams(() => [{
                    inviteCode:props.inviteCode,
                    storeNo:props.storeNo
                }])
                .showError()
                .mapError(() => {
                    toLogin()
                    return data.indexData
                })
                .reactive()
                .immediatelyLoad()

const toLogin = () => {
    emit('logout');
}

onMounted(()=>{

})

</script>

<style scoped>
    .box {
        background-color:var(--f7-theme-color);
        /* background: linear-gradient(180deg, var(--f7-theme-color) 0%, var(--f7-md-surface-5) 100%); */
        background-size: 100% 100%;
        height: 100%;
        width: 100%;
        position: fixed;
        overflow-y: scroll;
    }
    .box .box-1{
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.4));
        overflow: hidden;
    }
    .content {
        padding: 25px;
    }

    .logo {
        margin-top: 40px;
        text-align: center;
        display: flex;
        justify-content: center;

    }

    .content-list {
        margin: 30px 0 0 0;
        padding-bottom: 30px;
        border-radius: 10px;
        /* background: white; */
        height: 100%;
        position: relative;
        text-align: center;
    }
    .content-img{
        width: 27%
    }
    .content-info{
        /* background-color: #f5f5f5; */
        height: 100%;
        margin: auto 35px;
        padding: 15px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        border: 1px solid #DCDFE6;
        border-radius: 6px;
    }
    .content-middle{
        /* margin-left: 15px; */
        width: 73%;
        display:flex;
        flex-direction: column;
        align-items: self-start;
        /* justify-content: left; */
    }
    .content-span{
        border: 1px solid #cccccc;
        padding: 2px ;
        font-size: 13px;
        line-height: 1.2;
        border-radius: 5px;
        margin-top: 5px;
        margin-right: 10px;
        width: max-content;
    }
    .tab1{
        width: 104%;
        height: 20px;
        border-radius: 12px;
        background-color: var(--f7-ios-primary);
        position: absolute;
        top: -10px;
        left: -2%;
        /* opacity: 0.5; */
    }
    .tab1-top{
        background: linear-gradient(180deg, var(--f7-md-inverse-primary) 0%, #FFFFFF 14.68%);
        position: relative;
    }
</style>
