import { Router } from "framework7/types"
import Index from './index.vue'
const Step1 = () => import('./步骤/Step1.vue')
const Step2 = () => import('./步骤/Step2.vue')
const Contact = () => import('./步骤/Contact.vue')
const Store = () => import('./步骤/Store.vue')
const Bank = () => import('./步骤/Bank.vue')
const routes: Router.RouteParameters[] = [
    {
        path: '/manualEntry',
        component: Index
    },
    {
        name:'step1',
        path: '/manualEntry/step1',
        asyncComponent: Step1
    },
    {
        path: '/manualEntry/step2',
        asyncComponent: Step2
    },
    {
        path: '/manualEntry/Contact',
        asyncComponent: Contact
    },
    {
        path: '/manualEntry/Store',
        asyncComponent: Store
    },
    {
        path: '/manualEntry/Bank',
        asyncComponent: Bank
    }
]

export default routes
