import { resultData } from "src/http";

export function h5IndexPageApi(params:any) {
    return resultData.post<AppIndexBox>('/jdbt/jdbtH5Merchant/h5IndexPage',params);
}

export type AppIndexBox = {
    "title": string,
    "content": string,
    "leftBtn": string,
    "rightBtn": string,
    "btnTop": string,
    "btnDown": string,
    "topType": number,
    "downType": number,
    "url": string,
    "iosUrl": string,
    "bottomStr": string,
    "show": Boolean,
    "btnStr": string,
    "type": number
}
