<template>
    <div class="flex-1">
        <slot :open="open" :close="close">
            <input placeholder="请选择" readonly @click="open">
        </slot>
        <f7Sheet class="h-auto rounded-t-lg" v-model:opened="state.opened" swipe-to-close close-by-backdrop-click backdrop>
            <div class="h-[48px] p-4 text-sm">
                请选择地区
            </div>
            <div class="h-[56px] p-4">
                <TransitionGroup name="list">
                    <div class="item mx-2 inline-block" :class="{ selected: state.currentIndex === idx }"
                         v-for="item, idx in value" :key="item.code" @click="state.currentIndex = idx">
                        {{ item.name }}
                    </div>
                    <div class="item mx-2 inline-block" :class="{ selected: state.currentIndex === value.length }"
                         :style="{ opacity: isPlaceholderVisible ? 1 : 0 }" key=""
                         @click="state.currentIndex = value.length">请选择</div>
                </TransitionGroup>
            </div>
            <div class="h-[400px] overflow-x-hidden overflow-y-auto relative" @touchstart.stop="">
                <Transition :name="state.transition">
                    <f7List :key="state.currentIndex" class="w-full m-0">
                        <div v-if="loader.isLoading" class="flex justify-center">
                            <f7Preloader size="20px"></f7Preloader>
                        </div>
                        <f7ListItem v-else v-for="item in loader.value" :key="item.code" :title="item.name" link="#"
                                    no-chevron @click="onDistrictClick(item)">

                        </f7ListItem>
                    </f7List>
                </Transition>
            </div>
        </f7Sheet>
    </div>
</template>

<script setup lang="ts">
import { f7List, f7ListItem, f7Preloader, f7Sheet } from 'framework7-vue';
import { makeLoader } from 'src/Loader';
import { computed, reactive, watch } from 'vue';
import { District, getDistrict } from './api';

export type DistrictItem = {
    name: string
    code: string
}

const props = withDefaults(defineProps<{
    value: DistrictItem[],
    level?: number
}>(),{
    level: 4
})

const emit = defineEmits<{
    (e: 'update:value', value: DistrictItem[]): void
}>()

const state = reactive({
    opened: false,
    currentIndex: 0,
    transition: 'slide-right'
})

const cache = reactive<{ [key: string]: District }>({})

const isPlaceholderVisible = computed(() => {
    return props.value.length === 0 || cache[props.value[props.value.length - 1].code]?.hasChild
})

watch(() => [props.value, state.currentIndex], () => {
    loader.load()
})

watch(() => state.currentIndex, (value, old) => {
    if (value < old) {
        state.transition = 'slide-right'
    } else if (value > old) {
        state.transition = 'slide-left'
    }
})

const loader = makeLoader(getDistrict)
    .mapParams(() => {
        return [props.value[state.currentIndex - 1]?.code]
    })
    .onLoad(data => {
        const item = data.find(item => item.code === props.value[state.currentIndex]?.code)
        if (item) {
            cache[item.code] = item
        }
    })
    .reactive()
loader.load()



function onDistrictClick(district: District) {
    let level = parseInt(props.level);
    if(level < 0 || level > 4){
        level = 4
    }
    emit('update:value', [...props.value.slice(0, state.currentIndex), { name: district.name, code: district.code }])
    cache[district.code] = district
    if ((level - 1) > state.currentIndex) {
        state.currentIndex++
    } else {
        close()
    }
}

function open() {
    state.opened = true;
}

function close() {
    state.opened = false;
}

defineExpose({
    open,
    close
})

</script>

<style scoped>
.item {
    color: #000;
    border-color: transparent;
    border-style: solid;
    border-width: 0 0 2px 0;
    transition-property: color, border-color;
    transition-duration: 0.3s;
}

.item.selected {
    color: #007aff;
    border-color: #007aff;
}

/* 列表向右滑动 */
.slide-right-enter-from {
    transform: translateX(-100%);
}

.slide-right-enter-active,
.slide-right-leave-active {
    position: absolute;
    transition: transform 0.3s;
}

.slide-right-enter-to,
.slide-right-leave-from {
    transform: translateX(0);
}

.slide-right-leave-to {
    transform: translateX(100%);
}

/* 列表向左滑动 */
.slide-left-enter-from {
    transform: translateX(100%);
}

.slide-left-enter-active,
.slide-left-leave-active {
    position: absolute;
    transition: transform 0.3s;
}

.slide-left-enter-to,
.slide-left-leave-from {
    transform: translateX(0);
}

.slide-left-leave-to {
    transform: translateX(-100%);
}

.list-move,
/* 对移动中的元素应用的过渡 */
.list-enter-active,
.list-leave-active {
    transition: all 0.3s ease;
}

.list-enter-from {
    opacity: 0;
    transform: translateY(30px);
}

.list-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}

/* 确保将离开的元素从布局流中删除
  以便能够正确地计算移动的动画。 */
.list-leave-active {
    position: absolute;
}
</style>
