import { Router } from "framework7/types"
import Login from './登录/index.vue'
const Retrieve = () => import('./找回密码/index.vue')
const Privacy = () => import('./Privacy.vue')
const Register = () => import('./注册/index.vue')

const routes: Router.RouteParameters[] = [
    {
        path: '/login',
        component: Login
    },
    {
        path: '/retrieve',
        asyncComponent: Retrieve
    },
    {
        path: '/privacy',
        asyncComponent: Privacy
    },
    {
        path: '/register',
        asyncComponent: Register
    }
]

export default routes