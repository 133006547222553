<template>
    <div class="image">
        <div class="placeholder" :class="{ hide: !data.isLoading }"></div>
        <img :alt="alt" ref="imgRef" @load="onload" @error="onerror">
    </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, reactive, ref, watch } from 'vue';

const props = defineProps<{
    src: string
    alt?: string
    lazy?: boolean
}>();

const imgRef = ref<HTMLImageElement>();

watch(() => props.src, src => {
    const img = imgRef.value!;
    if (img.src && img.src !== src) {
        img.src = src;
        data.isLoading = true
    }
})

onMounted(() => {
    const img = imgRef.value!;
    if (!props.lazy) {
        img.src = props.src;
        data.isLoading = true
    } else {
        const observer = new IntersectionObserver(
            ([e]) => {
                if (e.isIntersecting) {
                    img.src = props.src;
                    data.isLoading = true
                    observer.disconnect();
                }
            },
            { threshold: [0] }
        );
        nextTick(() => observer.observe(img)) // wait for the element to be rendered
        return () => observer.disconnect();
    }
})


const data = reactive({
    isLoading: false,
    error: undefined as Event | undefined,
})

function onload() {
    data.isLoading = false
}

function onerror(e: Event) {
    data.isLoading = false
    data.error = e
}

</script>

<style scoped>
.image {
    position: relative;
    overflow: hidden;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: inherit;
    object-position: inherit;
}

.placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
}

.placeholder.hide {
    transition: opacity 0.3s;
    opacity: 0;
}
</style>