<template>
  <f7App :routes="routers" theme="ios" :touch="{ disableContextMenu: true }"
         :dialog="{ buttonOk: '确认', buttonCancel: '取消' }">

    <!-- //-1首页 0选择通道  1支付宝 2微信 3京东 4信用卡 5扫呗 -->
    <Home v-if="login.isLogin && pageType === '-1'"></Home>
    <f7View v-if="login.isLogin && pageType === '0' && login.code()" stack-pages url="/selectChannel" class="safe-areas" name="selectChannel"></f7View>
    <f7View v-if="login.isLogin && pageType === '1' && login.code()" stack-pages url="/manualZFBEntry" class="safe-areas" name="manualZFBEntry"></f7View>
    <f7View v-if="login.isLogin && pageType === '3' && login.code()" stack-pages url="/manualEntry" class="safe-areas" name="manualEntry"></f7View>
    <f7View v-if="login.isLogin && pageType === '4' && login.code()" stack-pages url="/creditCard" class="safe-areas" name="creditCard"></f7View>
    <f7LoginScreen :opened="!login.isLogin">
      <f7View stack-pages url="/login" class="safe-areas" name="login"></f7View>
    </f7LoginScreen>
  </f7App>
</template>

<script setup lang="ts">
import { f7App, f7LoginScreen, f7View } from 'framework7-vue';
import Home from './pages/Home/index.vue';
import routers from './routes';
import { sharedLogin } from './Controllers/LoginConrtoller';
import { getCurrentInstance , ref } from 'vue'

const pageType = ref('');

const instance:any = getCurrentInstance();
instance?.proxy?.$bus.on('pageType',(e:any) => {
    pageType.value = e;
    
})

const login = sharedLogin

</script>

<style scoped>
.framework7-root {
  background-color: var(--f7-page-bg-color);
}
</style>
