<template>
    <f7Link @click="scan">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 2.25H3C2.58579 2.25 2.25 2.58579 2.25 3V6" stroke="#C4C4C4" stroke-linecap="round"
                  stroke-linejoin="round" />
            <path d="M6 15.75H3C2.58579 15.75 2.25 15.4142 2.25 15V12" stroke="#C4C4C4" stroke-linecap="round"
                  stroke-linejoin="round" />
            <path d="M12 15.75H15C15.4142 15.75 15.75 15.4142 15.75 15V12" stroke="#C4C4C4" stroke-linecap="round"
                  stroke-linejoin="round" />
            <path d="M12 2.25H15C15.4142 2.25 15.75 2.58579 15.75 3V6" stroke="#C4C4C4" stroke-linecap="round"
                  stroke-linejoin="round" />
            <path d="M12.75 9H5.25" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.125 6H7.875" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.125 12H7.875" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <QrCodeScanner ref="scaner"/>
    </f7Link>
</template>

<script setup lang="ts">
import { f7Link } from 'framework7-vue';
import QrCodeScanner from 'src/components/QrCodeScanner.vue';
import QrCodeScannerCopy from 'src/components/QrCodeScannerCopy.vue';
import { ref } from 'vue';

const props = defineProps<{
    imei: string
}>();

const emit = defineEmits<{
    (e: 'update:imei', imei: string): void
}>()

const scaner = ref<InstanceType<typeof QrCodeScanner>>()
function scan() {
    scaner.value?.scan().then((result) => {
        emit('update:imei', result)
    })
}
</script>

<style scoped>

</style>