import { Loader } from "../Loader";

declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        /**
         * Set the type of the value returned by this loader.
         * note: this method is only used for type inference, it does not change the behavior of the loader.
         */
        setValueType<NewValue>(): Loader<NewValue, Params>
        /**
         * Set the type of the params accepted by this loader.
         * note: this method is only used for type inference, it does not change the behavior of the loader.
         */
        setParamsType<NewParams extends any[]>(): Loader<Value, NewParams>
    }
}

Loader.prototype.setValueType = function () {
    return this
}

Loader.prototype.setParamsType = function () {
    return this
}