import { FuncLoader } from '../FuncLoader';
import { Loader } from '../Loader';

declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        modifyValue(modifier: (value: Value) => Value | PromiseLike<Value>): Loader<Value, Params>
        modifyParams(modifier: (...args: Params) => Params | PromiseLike<Params>): Loader<Value, Params>
    }
}

Loader.prototype.modifyValue = function (modifier) {
    return new FuncLoader(async (...args) => modifier(await this.load(...args)))
}


Loader.prototype.modifyParams = function (modifier) {
    return new FuncLoader(async (...args) => this.load(...await modifier(...args)))
}

