<template>
    <div class="plain-select">
        <slot>
            <select>
                <option>请选择</option>
            </select>
        </slot>
        <svg class="icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M14.0303 6.14155C13.7374 5.84865 13.2626 5.84865 12.9697 6.14155L9 10.1112L5.03033 6.14154C4.73744 5.84865 4.26256 5.84865 3.96967 6.14154C3.67678 6.43444 3.67678 6.90931 3.96967 7.2022L8.46967 11.7022C8.76256 11.9951 9.23744 11.9951 9.53033 11.7022L14.0303 7.2022C14.3232 6.90931 14.3232 6.43444 14.0303 6.14155Z"
                  fill="black" />
        </svg>
    </div>

</template>

<script setup lang="ts">

</script>

<style scoped>
.plain-select {
    position: relative;
    display: inline-block;
    border-radius: 6px;
    color: var(--f7-label-text-color);
    transition-property: color background-color;
    transition-duration: 250ms;
}

.plain-select :slotted(select) {
    height: 32px;
    padding-left: 12px;
    padding-right: 32px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.plain-select .icon {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    height: 18px;
    bottom: 18px;
    pointer-events: none;
}

.plain-select:focus-within {
    color: #989898;
    background-color: #F2F2F2;
}

svg {
    transition-property: opacity;
    transition-duration: 250ms;
}

.plain-select:focus-within svg {
    opacity: 0.3;
}
</style>