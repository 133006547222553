<template>
    <div class="date-input" :data-empty="!displayValue">
        {{ displayValue || placeholder }}<slot name="icon">
            <svg class="h-[18px] w-[18px] mx-1" width="18" height="18" viewBox="0 0 18 18" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M14.0303 6.14155C13.7374 5.84865 13.2626 5.84865 12.9697 6.14155L9 10.1112L5.03033 6.14154C4.73744 5.84865 4.26256 5.84865 3.96967 6.14154C3.67678 6.43444 3.67678 6.90931 3.96967 7.2022L8.46967 11.7022C8.76256 11.9951 9.23744 11.9951 9.53033 11.7022L14.0303 7.2022C14.3232 6.90931 14.3232 6.43444 14.0303 6.14155Z"
                      fill="black" />
            </svg>
        </slot>
        <input required :value="inputValue()" @input="setDate($event.target.value)"
               @click="initValue = $event.target.value" :type="type">
    </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import Dayjs from 'dayjs'

const props = withDefaults(defineProps<{
    value?: Date | null,
    type?: 'date' | 'datetime-local' | 'time' | 'month',
    format?: string,
    placeholder?: string
}>(), {
    type: 'datetime-local',
    placeholder: '请选择'
})

const emit = defineEmits<{
    (event: 'update:value', v?: Date): void
}>()

const date = computed(() => {
    if (props.value === undefined) {
        return data._date
    } else {
        return props.value
    }
})

const displayValue = computed(() => {
    if (date.value) {
        return Dayjs(date.value).format(props.format ?? {
            'date': 'YYYY-MM-DD',
            'datetime-local': 'YYYY-MM-DD HH:mm',
            'time': 'HH-mm',
            'month': 'YYYY-MM'
        }[props.type])
    } else {
        return ''
    }
})

function inputValue() {
    if (date.value) {
        return Dayjs(date.value).format({
            'date': 'YYYY-MM-DD',
            'datetime-local': 'YYYY-MM-DD HH:mm',
            'time': 'HH-mm',
            'month': 'YYYY-MM'
        }[props.type])
    } else {
        return ''
    }
}
let initValue = ref('')
function setDate(v: string) {
    if (v) {
        data._date = Dayjs(v).toDate()
        emit('update:value', data._date)
    } else {
        if (initValue.value) {
            data._date = Dayjs(initValue.value).toDate()
            emit('update:value', data._date)
        } else {
            data._date = undefined
            emit('update:value', undefined)
        }
    }
}

const data = reactive({
    _date: new Date() as Date | undefined
})

</script>

<style scoped>
.date-input {
    position: relative;
    color: #1E1E1E;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    line-height: 32px;
    border-radius: 6px;
    overflow: hidden;
    padding-left: 8px;

    transition-property: color opacity background-color;
    transition-duration: 250ms;

    display: inline-flex;
    align-items: center;
}

.date-input:focus-within {
    color: #989898;
    background-color: #F2F2F2;
}

.date-input[data-empty="true"] {
    color: #989898;
    font-weight: 400;
}

svg {
    transition-property: opacity;
    transition-duration: 250ms;
}

.date-input:focus-within svg {
    opacity: 0.3;
}

input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    user-select: none;
    opacity: 0.001;
}
</style>