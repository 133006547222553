import { FuncLoader } from "../FuncLoader"
import { Loader } from "../Loader"


declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        replaceError(value: Value): Loader<Value, Params>
    }
}

Loader.prototype.replaceError = function (value) {
    return new FuncLoader(async (...args) => {
        try {
            return await this.load(...args)
        } catch (e: any) {
            return value
        }
    })
}