<template>
    <Page v-bind="$props" title="活动列表" navbar tab-root>
        <template #nav-right>
            <ExitMenuLink/>
        </template>
        <ActivityList class="absolute h-full w-full" ref="list"></ActivityList>
    </Page>
</template>

<script setup lang="ts">
import { f7Link } from 'framework7-vue';
import { Router } from 'framework7/types';
import Page from 'src/components/Pages/Page.vue';
import { onMounted, ref } from 'vue';
import ExitMenuLink from 'src/pages/登录/登录/ExitMenuLink.vue';
import * as api from "../../订单/api";
import ActivityList from './ActivityList.vue'
const props = defineProps<{
    f7router: Router.Router
    f7route: Router.Route
}>();
const list = ref<InstanceType<typeof ActivityList>>()

onMounted(() => {
    list.value?.load()
})
</script>

<style scoped>

</style>
