import { http } from "src/http";

const cache = new Map<string, District[]>();

export function getDistrict(parentCode?: string) {
    if (cache.has(parentCode || '')) {
        return Promise.resolve(cache.get(parentCode || '')!);
    }
    return http.resultData.post<District[]>(`/jdbt/jdbtWebMerchant/getAreaList`, { parentCode }).then((data) => {
        cache.set(parentCode || '', data);
        return data;
    });
}

export function getJhDistrict(parentCode?: string) {
    if (cache.has(parentCode || '')) {
        return Promise.resolve(cache.get(parentCode || '')!);
    }
    return http.resultData.post<District[]>(`/jhserver/area/app/v1/getAreaList`, { parentCode }).then((data) => {
        cache.set(parentCode || '', data);
        return data;
    });
}

export function getBankDistrict(params?: any) {
    return http.resultData.post<District[]>(`/jdbt/jdbtH5Merchant/getBankArea`, params).then((data) => {
        return data;
    });
}


export function getAlipayDistrict(parentCode?: string) {
    if (cache.has(parentCode || '')) {
        return Promise.resolve(cache.get(parentCode || '')!);
    }
    return http.resultData.post<District[]>(`/alipay/zft/area/web/v1/list`, { parentCode }).then((data) => {
        cache.set(parentCode || '', data);
        return data;
    });
}

export type District = {
    "code": string,
    "hasChild": boolean,
    "id": number,
    "level": number,
    "name": string
}
