import { Router } from "framework7/types"
import Index from './index.vue'
const routes: Router.RouteParameters[] = [
    {
        path: '/selectChannel',
        component: Index
    }
]

export default routes
