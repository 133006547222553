<template>
    <f7Popup :opened="data.isOpened" @popup:closed="onClose" swipe-to-close>
        <div  class="h-full w-full bg-black">
            <div :id="data.id" class="reader h-full w-full"></div>
        </div>
    </f7Popup>
</template>

<script setup lang="ts">
import { f7Popup } from 'framework7-vue'
import { Html5Qrcode } from "html5-qrcode";
import { onMounted, onUnmounted, reactive } from "vue";
const data = reactive({
    text: '',
    isOpened: false,
    id:'reader'+new Date().getTime()
})
let scan: () => Promise<string>
let scanner: Html5Qrcode | undefined

onMounted(() => {
    scanner = new Html5Qrcode(data.id);
})

onUnmounted(() => {
    scanner?.isScanning && scanner.stop()
})

scan = () => {
    data.isOpened = true
    return new Promise((resolve) => {
        scanner!.start(
            { facingMode: "environment" },
            { fps: 60, qrbox: { width: 250, height: 250 },aspectRatio: 1.777778 },
            (text, result) => {
                console.log(text, result)
                data.isOpened = false
                resolve(text)
                scanner!.stop()
            },
            (error) => { console.log(error); data.text = '扫描中...' }
        )
    })
}

function cancel() {
    scanner?.isScanning && scanner.stop()
    data.isOpened = false
}

function onClose() {
    scanner?.isScanning && scanner.stop()
    data.isOpened = false
}

defineExpose({
    scan
})


</script>

<style scoped>
:deep(.page-content) {
    background-color: black;
}

.reader :deep(video){
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
}
</style>