import Page404 from './pages/misc/404.vue';

import { Router } from "framework7/types";
import { f7 } from 'framework7-vue';
import TestAVue from './pages/misc/TestA.vue';
import TestBVue from './pages/misc/TestB.vue';
import TestCVue from './pages/misc/TestC.vue';

const IFrame = () => import('./pages/misc/IFrame.vue')

const subroutes = import.meta.glob('./pages/**/*/routes.ts',{eager: true})

const routes: Router.RouteParameters[] = [
    { path: '/', redirect: '/launch' },
    ...Object.values(subroutes).flatMap(r => r.default),
    { path: '/testa', component: TestAVue },
    { path: '/testb', component: TestBVue },
    { path: '/testc', component: TestCVue },
    { path: '/web', asyncComponent: IFrame },
    { path: '/:path(.*)', component: Page404 }
]

export default routes;











let routeChanging = false
let nextNavigation: (() => void) | undefined
let init = false

let _navigate: undefined | Router.Router['navigate']

export function useRouter(): Router.Router {
    return window.f7.views.current.router


    if (!init) {
        f7.on('pageBeforeIn', () => {
            routeChanging = true
        })
        f7.on('pageAfterIn', () => {
            routeChanging = false
            nextNavigation?.()
            nextNavigation = undefined
        })
        init = true
    }

    const router = f7.views.main.router
    if (!router) {
        throw Error('无法获取当前router')
    }
    if (!_navigate) {
        _navigate = router.navigate
        const newNavigate = (parameters: string | Router.NavigateParameters, options?: Router.RouteOptions) => {
            if (typeof parameters === 'string') {
                return _navigate!.call(router, resolvePath(router.currentRoute.path, parameters) as any, options)
            } else {
                return _navigate!.call(router, parameters, options)
            }
        }
        router.navigate = (parameters, options?) => {
            if (init && routeChanging) {
                nextNavigation = () => newNavigate(parameters, options)
                return router
            } else {
                return newNavigate(parameters, options)
            }
        }
    }
    return router
}

export function resolvePath(base: string, path: string): string {
    if (path.startsWith('../')) {
        return base.split('/').slice(0, -1).join('/') + path.substring(2)
    } else {
        return path
    }
}