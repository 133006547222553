import { Router } from "framework7/types"
import homePage from'./homePage.vue'
const shopList = () => import('./门店管理/index.vue')
const addShop = () => import('./门店管理/addShop.vue')
const editShop = () => import('./门店管理/editShop.vue')
const detailsShop = () => import('./门店管理/detailsShop.vue')
const pictureShop = () => import('./门店管理/picture.vue')
const ActivityDetail = () => import('./活动详情/index.vue')
const RefundList = () => import('./退款审批/index.vue')

const routes: Router.RouteParameters[] = [
    {
        path: '/homePage/index',
        component: homePage
    },
    {
        path: '/shopList/index',
        asyncComponent: shopList
    },
    {
        path: '/shopAdd/addShop',
        asyncComponent: addShop
    },
    {
        path: '/editShop/:editShop',
        asyncComponent: editShop
    },
    {
        path: '/shopPicture/:pictureShop',
        asyncComponent: pictureShop
    },
    {
        path: '/shop/:detailsShop',
        asyncComponent: detailsShop
    },
    {
        path: '/redActivity/detail',
        asyncComponent: ActivityDetail
    },
    {
        path: '/RefundList/list',
        asyncComponent: RefundList
    }
]

export default routes
