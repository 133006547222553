declare global {
    interface PromiseConstructor {
        delay(ms: number): Promise<void>
        setInterval<T>(generator: () => Promise<T>, timeout?: number): Cancellable;
    }

    interface Promise<T> {
        delay(ms: number): Promise<T>
    }

}
Promise.prototype.delay = function (ms) {
    return this.then((value) => {
        let token: number;
        let p = new Promise(resolve => {
            token = window.setTimeout(() => resolve(value), ms)
        });
        p.finally(() => window.clearTimeout(token));
        return p;
    })
}

Promise.delay = function (ms) {
    return new Promise<number>(resolve => {
        let a = window.setTimeout(() => resolve(a), ms)
    }).then(window.clearTimeout);
}

interface Cancellable {
    cancel(): void;
}

Promise.setInterval = <T>(generator: () => Promise<T>, timeout?: number) => {
    let cancelled = false;
    const loopBody = () => {
        if (cancelled) return;
        generator().delay(timeout || 0).then(loopBody)
    }
    loopBody()
    return { cancel: () => cancelled = true }
}

export { }