<template>
    <f7Link class="switch" @click="emit('update:modelValue', !modelValue)">
        <slot v-bind="{ isEnabled: modelValue }">
            {{ modelValue ? 'On' : 'Off' }}
        </slot>
    </f7Link>
</template>

<script setup lang="ts">
import { f7Link } from 'framework7-vue';
defineProps<{
    modelValue: boolean
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
}>();

</script>

<style scoped>
.switch {
    position: relative;
    flex: none;
}
</style>