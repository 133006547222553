<template>
    <Page v-bind="$props" title="登录" navbar large transparent>
        <template #nav-right>
            <f7NavRight>
                <ExitMenuLink />
            </f7NavRight>
        </template>
        <Transition name="slide-r" :duration="350">
            <PasswordLogin v-if="data.loginType === 'password'" @switch="data.loginType = $event" />
            <PhoneLogin v-else-if="data.loginType === 'phone'" @switch="data.loginType = $event" />
        </Transition>
            <!--<label class="mt-auto mb-10 flex justify-center items-center">
                <span class="mx-4 mt-4 otherLoginType">--其他登录方式--</span>
            </label>-->
            <label class="mt-auto mb-10 flex justify-center items-center" v-if="login.fromWechat()">
                <ActionButton class="mx-4 mt-4" fill >
                                <img @click="login.toWxLogin" src="./assets/Frame.svg" alt="">
                </ActionButton>
            </label>
        <label class="mt-auto mb-10 flex justify-center items-center">
            <f7Checkbox class="mr-2 mb-1" v-model:checked="login.agreePrivacy" />我已经阅读并同意<f7Link href="/privacy">《隐私政策》
            </f7Link>
        </label>
    </Page>
</template>


<script setup lang="ts">
import { f7Link, f7Checkbox, f7NavRight } from 'framework7-vue';
import { Router } from 'framework7/types';
import Page from 'src/components/Pages/Page.vue';

import PasswordLogin from './PasswordLogin.vue';

import { reactive } from 'vue';
import PhoneLogin from './PhoneLogin.vue';
import { sharedLogin } from 'src/Controllers/LoginConrtoller';
import ExitMenuLink from './ExitMenuLink.vue';
export type LoginType = 'password' | 'phone'

const props = defineProps<{
    f7router: Router.Router
    f7route: Router.Route
}>();

const data = reactive({
    loginType: 'password' as LoginType
});

const login = sharedLogin

</script>

<style scoped>
span.otherLoginType{
  position: absolute;
  margin-left:auto;
  margin-right:auto;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height */

  text-align: right;

  /* 灰 */

  color: #BBBBBB;
}
</style>