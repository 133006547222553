<template>
    <div :class="[data.tagClass,bg]" :data-tag="tag"></div>
</template>

<script setup lang="ts">
    import { computed,reactive } from 'vue';
    const p = defineProps<{
        tag?: string,
    }>()

    const data = reactive({
        tagClass: 'round-card-header-tag'
    })

    const bg = computed(() => {
        switch (p.tag) {
            case '京东贴息':
                return 'jd'
            case '商户贴息':
                return 'merchant'
            case '联合贴息':
                return 'union'
        }
    })

</script>

<style scoped>
.round-card-header-tag {
    float: right;
    height: 23px;
    width: 72px;
}
.jd{
    background: url("assets/jd-interest.svg");
}
.union{
    background: url("assets/union-interest.svg");
}
.merchant{
    background: url("assets/merchant-interest.svg");
}
</style>
