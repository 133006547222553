<template>
    <label class="round-label">
        <slot></slot>
    </label>

</template>

<script setup lang="ts">

</script>

<style scoped>
.round-label {
    background-color: var(--content-bg-color);
    border-radius: 8px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    padding: 0 16px;
    min-height: 56px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    color: #2D2D2D;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>