import { FieldDesc } from "src/components/DynamicFields/DynamicFields.vue";
import { resultData , http} from "src/http";
import { Paged, PagedParams } from "src/Loader";
import { defineEnum } from "src/modules/defineEnum";


//交易数据
export function getTransactionData(date:any) {
    return resultData.post<Paged<Order>>('/jhserver/index/app/v1/transactionData', {date})
}

// 权限数据
export function getPermissionData() {
    return resultData.post('/jhserver/index/app/v1/permissionData')
}

//门店列表
export function getStoreList(params: PagedParams & {
    auditState?: OrderState,
    // id: number,
    storeName: string
}) {
    return resultData.post<Paged<Order>>('/jhserver/store/app/v1/list', params)
}

//门店状态列表
export function getStoreStateList() {
    return resultData.post('/jhserver/store/app/v1/stateList')
}

//修改门店支付状态状态
export function changeStorePayState(param: string){
    return http.resultData.post('/jhserver/store/app/v1/changeStorePayState',param)
}

//修改门店状态
export function changeStoreState(param: string){
    return http.resultData.post('/jhserver/store/app/v1/changeStoreState',param)
}

//添加门店
export function addStore(param: string){
    return http.resultData.post('/jhserver/store/app/v1/add',param)
}

//门店详情
export function detailStore(param: string){
    return resultData.post('/jhserver/store/app/v1/detail',param)
}

//门店照片
export function pictureStore(param: string){
    return resultData.post('/jhserver/store/app/v1/picture',param)
}

//编辑门店
export function editStore(param: string){
    return http.resultData.post('/jhserver/store/app/v1/edit',param)
}

//跳转进件
export function getRegisterState() {
    return resultData.post('/jhserver/index/app/v1/registerState')
}

//活动红包弹窗
export function getActivityRedPacketShow() {
    return resultData.post('/jhserver/marketingactivity/app/v1/show')
}

//活动说明
export function getActivityList() {
    return resultData.post('/jhserver/marketingactivity/app/v1/list')
}

//退款申请数量
export function refundApplyCount(params: any) {
    return resultData.post<any>('/jhserver/refund/apply/app/v1/count', params);
}

//退款申请列表
export function refundApplyList(params: any) {
    return resultData.post<any>('/jhserver/refund/apply/app/v1/list', params);
}

/**
 * 金额预测
 */
export function compute(params: {
    "amount": string,
    "isFeeSeller": 0 | 1,
    "num": number,
    "payKey": string,
    "payeeKey": string,
    "tradeNo": string
}) {
    return resultData.post<{
        "amount": string,
        "num": number,
        "totalAmount": string
    }>('/jhserver/order/app/v1/compute', params)
}

export type QRCollectionParams = {
    "activityId"?: number,
    "amount"?: string,
    "customerName"?: string,
    "customerPhone"?: string,
    "imei"?: string,
    "isFeeSeller"?: 0 | 1,
    "num"?: string | number,
    "payKey"?: string,
    "payeeKey"?: string,
    "productName"?: string,
    "productSpec"?: string,
    "tradeNo"?: string
}

/**
 *  @return tradeNo 用于查询收款二维码
 * */
export function qrcollection(params: QRCollectionParams) {
    return resultData.post<{ tradeNo: string }>('/jhserver/order/app/v2/qr', params)
}
export function payDetail(tradeNo: string) {
    return resultData.post('/jhserver/order/app/v2/payDetail', { tradeNo })
}
export function refund(tradeNo: string) {
    return resultData.post('/jhserver/order/app/v1/refund', { tradeNo })
}
export function refreshQr(tradeNo: string) {
    return resultData.post('/jhserver/order/app/v2/refreshQr', { tradeNo })
}
export function getOrderCount() {
    return resultData.post('/jhserver/order/app/v2/getOrderCount')
}


export type OrderDetail = {
    "disabledChange": boolean,
    "id": number,
    "orderInfo": [
        {
            "key": string,
            "keyColor": string,
            "value": string,
            "valueColor": string
        }
    ],
    "payInfo"?: [
        {
            "key": string,
            "keyColor": string,
            "value": string,
            "valueColor": string
        }
    ],
    "payeeInfo"?: PayeeInfo[],
    "showPayeeInfo": boolean,
    "showQrBtn": boolean,
    "showRefundBtn": boolean,
    "showScanBtn": boolean,
    "state": OrderState,
    "storeName": string,
    "amount": string,
    "tradeNo": string,
    "merchantNo": string,
    "showDialog": false,
    "reason": string,
}

export type PayeeInfo = {
    "choose": boolean,
    "key": string,
    "payInfo": PayInfo[],
    "value": string
}

export type PayInfo = {
    "choose": boolean,
    "insCal": boolean,
    "key": string,
    "numList"?: NumInfo[],
    "showQrBtn": boolean,
    "showScanBtn": boolean,
    "value": string
}

export type NumInfo = {
    "change": boolean,
    "choose": boolean,
    "condition": 0 | 1,
    "isFeeSeller": boolean,
    "key": string,
    "show": boolean,
    "value": string
}

export const OrderState = defineEnum({
    //0 待支付 1 交易成功 -1 交易关闭 2 退款中 3 已退款
    待支付: 0 as OrderState,
    交易成功: 1 as OrderState,
    交易关闭: -1 as OrderState,
    退款中: 2 as OrderState,
    已退款: 3 as OrderState
})

export type OrderState = -1 | 0 | 1 | 2 | 3;

export type Order = {
    "id": number,
    "orderInfo": FieldDesc[],
    "showCloseBtn": boolean,
    "showPayBtn": boolean,
    "state": OrderState,
    "title": string,
    "tradeNo": string
}