<template>
    <div class="round-desc" :class="{ error, divide }">
        <RoundLabel>
            <span class="name">{{ name }}</span>
            <slot></slot>
            <img class="flex-none w-6" v-if="error !== undefined" :src="error ? wrong : correct" alt="">
        </RoundLabel>
        <div class="remark" v-if="remark || errorMessage">{{ remark || errorMessage }}</div>
    </div>
</template>

<script setup lang="ts">
import RoundLabel from './RoundLabel.vue';
import correct from './assets/correct.svg'
import wrong from './assets/wrong.svg'
import { computed } from 'vue';

const props = withDefaults(defineProps<{
    name: string
    divide?: boolean
    remark?: string
    error?: string | boolean
}>(), {
    error: undefined
})

const errorMessage = computed(() => typeof props.error === 'string' ? props.error : undefined)

</script>

<style scoped>
.name {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    flex: none;
    display: flex;
    align-items: center;

    /* Gray */
    color: #9B9B9B;
}

.divide .name {
    position: relative;
    padding-right: 10px;
    margin-right: 10px;
}

.divide .name::after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 0px;
    border-right: 2px solid var(--f7-bars-border-color);

}

.remark {
    margin-top: 8px;
    padding: 0 16px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: #2D2D2D;
}

.error .remark {
    color: var(--f7-input-error-text-color);
}

.error>.round-label {
    border: 1px solid var(--f7-input-error-text-color);
}
</style>
