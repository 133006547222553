import { Router } from "framework7/types"
import Collect from'./收款内容/index.vue'
const Code = () => import('./收款码/index.vue')
const succeed1 = () => import('./交易成功/index.vue')
const ActivityCollection = () => import('./活动收款/handCollection.vue')
const fixedCollection = () => import('./活动收款/fixedCollection.vue')
const classify = () => import('./活动收款/classify.vue')

const routes: Router.RouteParameters[] = [
    {
        path: '/collection/new',
        component: Collect
    },
    {
        path: '/collection/:tradeNo',
        asyncComponent: Code
    },
    {
        path: '/proceeds/:succeedContent1',
        asyncComponent: succeed1
    },
    {
        path: '/activity/:collection',
        asyncComponent: ActivityCollection
    },
    {
        path: '/fixed/:packageCategoryId',
        asyncComponent: fixedCollection
    },
    {
        path: '/classify/:payChannelType',
        asyncComponent: classify
    }
]

export default routes
