import { Router } from "framework7/types"
import Profile from './个人信息/index.vue'
const Settings = () => import('./设置/index.vue')
const ChangePassword = () => import('./修改密码/index.vue')
const BindPhone = () => import('./绑定手机号/index.vue')
const unBindPhone = () => import('./解绑手机号/index.vue')
const Phone = () => import('./手机号/index.vue')
const ChangePhone = () => import('./更换手机号/index.vue')
const Wallet = () => import('./我的钱包/index.vue')
const accountSetting = () => import('./我的钱包/accountSetting.vue')
const withdrawDeposit = () => import('./提现/index.vue')
const withdrawState = () => import('./提现/state.vue')
const walletRecharge = () => import('./钱包充值/index.vue')
const walletRechargeState = () => import('./钱包充值/state.vue')

const routes: Router.RouteParameters[] = [
    {
        path: '/user/profile',
        component: Profile
    },
    {
        path: '/user/settings',
        asyncComponent: Settings
    },
    {
        path: '/user/password',
        asyncComponent: ChangePassword
    },
    {
        path: '/user/phone',
        asyncComponent: Phone
    },
    {
        path: '/user/phone/bind',
        asyncComponent: BindPhone
    },
    {
        path: '/user/phone/unBind/:phone',
        asyncComponent: unBindPhone
    },
    {
        path: '/user/phone/change',
        asyncComponent: ChangePhone
    },
    {
        path: '/user/wallet',
        asyncComponent: Wallet
    },
    {
        path: '/user/accountSetting',
        asyncComponent: accountSetting
    },
    {
        path: '/user/withdrawDeposit',
        asyncComponent: withdrawDeposit
    },
    {
        path: '/user/withdrawState/:withdrawState',
        asyncComponent: withdrawState
    },
    {
        path: '/user/walletRecharge/index',
        asyncComponent: walletRecharge
    },
    {
        path: '/user/walletRechargeState/:tradeNo',
        asyncComponent: walletRechargeState
    }
]

export default routes
