<template>
    <template v-if="enabled">
        <f7Link @click="actionsOpened = true">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10ZM19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                      fill="#09121F" />
            </svg>
        </f7Link>
        <f7Actions v-model:opened="actionsOpened" backdrop closeByBackdropClick>
            <f7ActionsGroup>
                <f7ActionsLabel>请选择</f7ActionsLabel>
                <f7ActionsButton bold color="red" @click="exit">返回首页</f7ActionsButton>
            </f7ActionsGroup>
            <f7ActionsGroup>
                <f7ActionsButton>取消</f7ActionsButton>
            </f7ActionsGroup>
        </f7Actions>
    </template>
</template>

<script setup lang="ts">
import { f7Actions, f7ActionsButton, f7ActionsGroup, f7ActionsLabel, f7Link } from 'framework7-vue';
import { ref } from 'vue';

const actionsOpened = ref(false);

const enabled = !!window.$native

function exit() {
    window.$native?.window.close()
}
</script>

<style scoped>

</style>