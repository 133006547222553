<template>
   <Page v-bind="$props" title="订单列表" navbar tab-root>
       <template #nav-right>
           <ExitMenuLink/>
       </template>
       <div v-if="todoList.markFlag._object.markFlag">
            <div v-show="beginning()"></div>
        </div>
       <shopOrderList class="absolute h-full w-full" ref="list"></shopOrderList>
   </Page>
</template>

<script setup lang="ts">
import { f7Link, f7Tab, f7Tabs, f7Toolbar } from 'framework7-vue';
import { Router } from 'framework7/types';
import Page from 'src/components/Pages/Page.vue';
import { onMounted, ref,onActivated } from 'vue';
import ExitMenuLink from 'src/pages/登录/登录/ExitMenuLink.vue';
import shopOrderList from './shopOrderList.vue'
import { storeToRefs } from 'pinia';
import {useTodoStore} from '../../../stores/mark'
import System from '@zxing/library/esm/core/util/System';
const props = defineProps<{
   f7router: Router.Router
   f7route: Router.Route
}>();

const list = ref<InstanceType<typeof shopOrderList>>()
const todoStore  = useTodoStore()
const todoList:any = storeToRefs(todoStore)

const beginning =()=>{
    return setTimeout(()=>{
      list.value?.load()
    },0)
}

onMounted(() => {
   // list1.value?.load()
   // list2.value?.load()
})


</script>

<style scoped>
.toolbar {
   height: 44px;
   box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.03);
}

.toolbar .link {
   font-size: 16px;
   font-weight: 500;
}
.badge1{
   /* position:absolute; */
   font-size: 10px;
   font-weight: 400;
   letter-spacing: 0em;
   text-align: center;
   height: 16px;
   line-height: 17px;
   border-radius: 50%;
   color: #FFFFFF;
   background-color: #E34D59;
   width: 16px;
}
</style>