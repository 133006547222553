<template>
    <div class="order-list" v-lazy-root>
        <!-- 搜索 -->
        <div class="flex justify-between headerInput mb-2">
            <div class="flex" style="width: 85%;">
                <div class="icon-inp">
                    <img class="w-[12px]" src="../../首页/assets/search.svg" alt="">
                </div>
                <f7-input class="text-input" label="搜索" type="text" placeholder="请输入"  v-model:value="storeNameOrTradeNo" @input="inputStoreName()" clear-button>
                    <template v-slot:media>
                    <f7-icon icon="demo-list-icon"></f7-icon>
                    </template>
                </f7-input>
                <div class="input-right"></div>
            </div>   
            <div class="text-inp" @click="storeNameOrTradeNo='',lastDataId='',reloadOrderList()">取消</div>
        </div>
        <div class="ml-4 mb-4">
            <DateInput v-model:value="date" type="date" format="YYYY-MM-DD" @update:value="lastDataId='',reloadOrderList()"></DateInput>
            <PlainSelect class="ml-4">
                <select v-model="state" @change="lastDataId='',reloadOrderList()">
                    <option  v-for="item in StateList" :value="item.state">{{ item.desc }}</option>
                </select>
            </PlainSelect>
        </div>
        <div class="empty" v-if="orderList.page === undefined || orderList.page.length <= 0">
            <div>
                <img src="../订单列表/assets/empty.svg" alt="">
                <p>暂无订单</p>
            </div>
        </div>
        <f7Link class="cell" v-for="item in orderList.page" :key="item.id" :href="`/order/${item.tradeNo}`">
            <RoundCard>
                <RoundCardHeader class="order-header" :title="item.title" :tag="api.OrderState[item.state]">
                </RoundCardHeader>
                <div class="p-4">
                    <DynamicFields :fields="item.orderInfo"></DynamicFields>
                    <div class="flex justify-end mt-3" v-if="item.showCloseBtn || item.showPayBtn">
                        <f7Button class="w-[98px]" round outline @click="askCloseOrder(item.tradeNo)">关闭订单</f7Button>
                        <f7Button class="w-[98px] ml-3" round fill :href="`/collection/${item.tradeNo}`">去收款</f7Button>
                    </div>
                </div>
            </RoundCard>
        </f7Link>
        <div class="flex justify-center py-4" key="bottom-loader" v-appear="() => loadMore1()">
            <f7Preloader size="20" v-if="!(orderList.page === undefined || orderList.page.length <= 0 || orderList1.page === undefined || orderList1.page.length <= 0)"></f7Preloader>
        </div>
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { f7Button, f7Preloader, f7Link , f7Input } from 'framework7-vue';
import DynamicFields from 'src/components/DynamicFields/DynamicFields.vue';
import DateInput from 'src/components/Inputs/DateInput.vue';
import PlainSelect from 'src/components/Inputs/PlainSelect.vue';
import RoundCard from 'src/components/RoundControls/RoundCard.vue';
import RoundCardHeader from 'src/components/RoundControls/RoundCardHeader.vue';
import { makeLoader, makePagedLoader } from 'src/Loader';
import { computed, onMounted, reactive, ref } from 'vue';
import * as api from '../api';


const date = ref(new Date());
const state = ref<api.OrderState>();
const StateList:any = ref({}); //状态列表 
const orderList:any = reactive(makePagedLoader(api.getOrderFormList));
const orderList1:any = reactive(makePagedLoader(api.getOrderFormList));
const storeNameOrTradeNo = ref('');
const inpFlag = ref(true);
const lastDataId = ref('')
const limit = ref(10)

const closeOrder = makeLoader(api.closeOrder).withIndicator()

function askCloseOrder(tradeNo: string) {
    lastDataId.value = '';
    limit.value = orderList.page.length;
    window.f7.dialog.confirm('','确定关闭此订单？', () => {
        closeOrder.load(tradeNo).then(reloadOrderList)
    }).open()
}

function reloadOrderList() {
    return orderList.withIndicator().load({ 
        state: state.value,
        date: dayjs(date.value).format('YYYY-MM-DD'),
        storeNameOrTradeNo:storeNameOrTradeNo.value,
        id:lastDataId.value,
        limit:limit.value
    })
}

function Refresh(){
    lastDataId.value = '';
    reloadOrderList()
}

function reloadOrderList1() {
    return orderList1.withIndicator().load({ state: state.value, date: dayjs(date.value).format('YYYY-MM-DD') , storeNameOrTradeNo:storeNameOrTradeNo.value, id:lastDataId.value })
}

defineExpose({
    load: Refresh,
    total: computed(() => orderList.total)
})

//门店状态列表  
const StoreStateList=async()=>{
    let res = await api.getOrderFormStateList()
    StateList.value = res
    state.value = res[0].state
}

//输入框节流
function inputStoreName(){
    lastDataId.value='';
    if(inpFlag.value){
        inpFlag.value = false;
        setTimeout(()=>{
            reloadOrderList()
            inpFlag.value = true;
        },1000)
    }
}

async function loadMore1() {
        lastDataId.value = await orderList.page[orderList.page.length - 1].id
        await reloadOrderList1()
        orderList.page = orderList.page.concat(orderList1.page)
    }

onMounted(()=>{
    StoreStateList()
    reloadOrderList()
})


</script>

<style scoped>
.headerInput{
    height: 48px;
    padding: 8px 16px 8px 16px;
    background-color: #FFFFFF;
}
.text-input{
    display: flex;
    width: 267px;
    height: 32px;
    justify-content: space-between;
    background-color: #f8f8f8;
}
.border-inp{
    display: flex;
    align-items: center;
    padding-left: 8px;
    color: #DCDCDC;
    background-color: #f8f8f8;
}
.icon-inp{
    display: flex;
    justify-content: space-evenly;
    background-color: #f8f8f8;
    border-radius: 8px 0 0 8px;
    width: 28px;
}
.text-inp{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* background-color: #f8f8f8; */
    /* border-radius:0 8px 8px 0; */
    width: 48px;
    color: #DB3022;
}
.input-right{
    background-color: #f8f8f8;
    width: 12px;
    border-radius: 0 8px 8px 0;
}
.order-list {
    overflow-y: auto;
    /* padding-top: 16px; */
    padding-bottom: 16px;
}

.cell {
    display: block;
    margin-left: 16px;
    margin-right: 16px;
}

.cell+.cell {
    margin-top: 16px;
}

.empty {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}
.empty p {
    text-align: center;
    color: #989898;
    font-size: 16px;
    margin: 0;
}

.order-header :deep(.tag[data-tag="交易成功"]) {
    background: linear-gradient(279.91deg, #DB3022 0.17%, #F4847B 93.61%);
}

.order-header :deep(.tag[data-tag="交易关闭"]) {
    background: linear-gradient(99.09deg, #DCDCDC 0%, #BFBFBF 100%);
}

.order-header :deep(.tag[data-tag="退款中"]) {
    background: linear-gradient(279.24deg, #F79E1B 0.74%, #FFC269 99.31%);
}

.order-header :deep(.tag[data-tag="已退款"]) {
    background: linear-gradient(279.24deg, #FFCF87 0.74%, #FFD596 99.31%);
}
</style>
