import.meta.glob('./Loaders/*.ts', { eager: true })
import { type AsyncFunc, FuncLoader } from "./FuncLoader";
import { Paged, PagedParams } from "./Loaders/PagedLoader";

export function makeLoader<Func extends AsyncFunc<any, any>>(func: Func) {
    return FuncLoader.init(func)
}

export function makePagedLoader<Value extends Paged<any>, Params extends [PagedParams]>(func: AsyncFunc<Params,Value>) {
    type Data = Value extends Paged<infer Data> ? Data : never
    return FuncLoader.init<AsyncFunc<Params,Paged<Data>>>(func).paged()
}

export type { Result } from "./Loaders/ResultData"
export { Loader } from "./Loader";
export { type Paged, type PagedParams, PagedLoader } from "./Loaders/PagedLoader";